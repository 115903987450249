import React from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../utils/firebaseConfig';
import { signOut } from 'firebase/auth';
import '../styles/Sidebar.css';

const Sidebar = ({ userInfo, openRegistrationPopup }) => {
    const navigate = useNavigate();

    const handleLogOut = async () => {
        await signOut(auth);
        navigate('/'); // Redirect to login after logout
    };

    return (
        <aside className="sidebar">
            <nav className="nav">
                <button onClick={() => navigate('/dashboard')}>Home</button>
                <button onClick={() => navigate('/employees')}>Employees</button>
                <button onClick={() => navigate('/locations')}>Locations</button> {/* Add Locations button */}
            </nav>
            {/* User Info at the bottom */}
            <div>
                {userInfo && (
                    <div className="user-info" onClick={openRegistrationPopup}>
                        <span>{userInfo.firstName} {userInfo.lastName}</span>
                    </div>
                )}
                <button className="out" onClick={handleLogOut}>Logout</button>
            </div>
        </aside>
    );
};

export default Sidebar;
