import React, { useState, useEffect } from 'react';
import { auth, firestore } from '../utils/firebaseConfig';
import { signOut } from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import '../styles/Frame.css';

const Frame = ({ children }) => {
    const [userInfo, setUserInfo] = useState(null); // Firestore profile data
    const [showRegistration, setShowRegistration] = useState(false); // Popup visibility
    const [tempFirstName, setTempFirstName] = useState(''); // Temp state for form data
    const [tempLastName, setTempLastName] = useState('');
    const [tempContactEmail, setTempContactEmail] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        // Check if the user's profile exists in Firestore
        const checkUserProfile = async () => {
            const user = auth.currentUser; // Get the currently logged-in user directly from auth

            if (user) {
                const userDocRef = doc(firestore, 'users', user.uid);
                const userDoc = await getDoc(userDocRef);

                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    setUserInfo(userData); // Load user profile data
                } else {
                    setShowRegistration(true); // Show the registration popup if no profile
                }
            }
        };

        checkUserProfile(); // Call the function to check profile
    }, []);

    const openRegistrationPopup = () => {
        // Load current values into temporary state before opening the form
        if (userInfo) {
            setTempFirstName(userInfo.firstName || '');
            setTempLastName(userInfo.lastName || '');
            setTempContactEmail(userInfo.contactEmail || '');
        }
        setShowRegistration(true);
    };

    const handleRegistration = async (e) => {
        e.preventDefault();
        try {
            const user = auth.currentUser; // Get the user from auth

            if (user) {
                // Create or update user profile in Firestore
                await setDoc(doc(firestore, 'users', user.uid), {
                    firstName: tempFirstName,
                    lastName: tempLastName,
                    contactEmail: tempContactEmail,
                    email: user.email, // Store the user's Firebase email
                });

                setUserInfo({
                    firstName: tempFirstName,
                    lastName: tempLastName,
                    contactEmail: tempContactEmail,
                }); // Update the local state
                setShowRegistration(false); // Hide the registration popup
            }
        } catch (error) {
            console.error('Error creating user profile:', error.message);
        }
    };

    const handleCancel = () => {
        setShowRegistration(false); // Close the popup without saving changes
    };

    const handleLogOut = async () => {
        await signOut(auth);
        navigate('/'); // Redirect to login after logout
    };

    return (
        <div className="frame">
            {/* Sidebar is part of the frame */}
            <Sidebar
                userInfo={userInfo}
                openRegistrationPopup={openRegistrationPopup}
                handleLogOut={handleLogOut}
            />

            {/* Main content area for different pages */}
            <div className="content">
                {children}
            </div>

            {/* Registration Popup */}
            {showRegistration && (
                <div className="registration-popup">
                    <div className="popup-header">
                        <h2>Edit Your Profile</h2>
                        <button className="close-button" onClick={handleCancel}>×</button>
                    </div>
                    <form onSubmit={handleRegistration}>
                        <input
                            type="text"
                            placeholder="First Name"
                            value={tempFirstName}
                            onChange={(e) => setTempFirstName(e.target.value)}
                            required
                        />
                        <input
                            type="text"
                            placeholder="Last Name"
                            value={tempLastName}
                            onChange={(e) => setTempLastName(e.target.value)}
                            required
                        />
                        <input
                            type="email"
                            placeholder="Contact Email (Optional)"
                            value={tempContactEmail}
                            onChange={(e) => setTempContactEmail(e.target.value)}
                        />
                        <button type="submit">Save</button>
                        <button type="button" onClick={handleCancel}>Cancel</button>
                    </form>
                </div>
            )}
        </div>
    );
};

export default Frame;
