import React, { useState, useContext } from 'react';
import '../styles/Locations.css';
import { DataContext } from '../components/DataContext';

// Column-specific formatting function
const formatCellValue = (value, formatType) => {
    // Handle Firestore Timestamp objects
    if (value && typeof value === 'object' && value.seconds && value.nanoseconds) {
        return new Date(value.seconds * 1000).toLocaleString(); // Convert Firestore Timestamp to readable date
    }

    // Only apply formatting to numerical values, skip strings like Employee Name
    if (typeof value === 'number') {
        if (isNaN(value)) return '0'; // Handle NaN values as 0

        switch (formatType) {
            case 'currency':
                return `$${parseFloat(value).toFixed(2)}`; // Currency formatting
            case 'float':
                return parseFloat(value).toFixed(2); // Truncate to 2 decimal places
            case 'int':
                return parseInt(value, 10); // Convert to integer
            case 'percentage':
                return `${(parseFloat(value) * 100).toFixed(2)}%`; // Convert to percentage
            default:
                return value; // Return the value as is
        }
    }

    // If it's a string or non-numerical value, return it as is
    return value;
};

const LocationsDashboard = () => {
    // Access location KPIs from the context
    const { locationKpis, loadingLocation } = useContext(DataContext); // Added userInfo from context

    // Use state to keep track of the selected location
    const [selectedIndex, setSelectedIndex] = useState(0); // Index of the selected location

    // Handle rotating the locations when a smaller box is clicked
    const rotateLocations = (index) => {
        setSelectedIndex(index); // Update the selected location index
    };


    if (loadingLocation || !locationKpis || locationKpis.length === 0) {
        return <p>Loading Location Data...</p>;
    }

    // Get the current selected location and the other two locations
    const selectedLocation = locationKpis[selectedIndex];
    const otherLocations = locationKpis.filter((_, index) => index !== selectedIndex);

    // Calculate Gross Profit per Day
    const currentDay = new Date().getDate(); // Get the current day of the month
    const grossProfitPerDay = selectedLocation['gross_profit'] / currentDay;

    // Get days left in the month from the selected location
    const percentageToMonthEnd = selectedLocation['percent_to_month_end'];

    // Get leaderboard information based on Gross Profit
    const sortedLocations = [...locationKpis].sort((a, b) => b['gross_profit'] - a['gross_profit']);
    const firstPlace = sortedLocations[0]?.Location;
    const secondPlace = sortedLocations[1]?.Location;
    const thirdPlace = sortedLocations[2]?.Location;

    return (
        <div className="locations-dashboard">
            <div className="top-container">
                {/* Large box for the detailed view of the selected location */}
                <div className="location-box large">
                    <h2>{selectedLocation.Location}</h2>
                    <div className="data-group">
                        <div className="data-label">Gross Profit:</div>
                        <div className="data-value profit">{formatCellValue(selectedLocation['gross_profit'], 'currency')}</div>
                        <div className="data-label">Gross Profit Per Day:</div>
                        <div className="data-value profit">{formatCellValue(grossProfitPerDay, 'currency')}</div>
                        <div className="data-label">Net Sales:</div>
                        <div className="data-value revenue">{formatCellValue(selectedLocation['net_sales'], 'currency')}</div>
                        <div className="data-label">Cost of Goods:</div>
                        <div className="data-value expense">{formatCellValue(selectedLocation['cost_of_goods'], 'currency')}</div>
                        <div className="data-label">Daily Goal:</div>
                        <div className="data-value">{formatCellValue(selectedLocation['daily_goal'], 'currency')}</div>
                        <div className="data-label">% to Goal:</div>
                        <div className="data-value">{formatCellValue(selectedLocation['percent_to_goal'], 'percentage')}</div>
                        <div className="data-label">Labor vs Profit:</div>
                        <div className="data-value">{formatCellValue(selectedLocation['labor_vs_profit'], 'percentage')}</div>
                        <div className="data-label">Progress:</div>
                        <div className="data-value">{selectedLocation['progress']}</div>
                    </div>
                </div>

                {/* Legend and Leaderboard Box */}
                <div className="legend-leaderboard">
                    <div className="legend-title">Today's Overview</div>
                    <p><span className="data-label">Today's Date:</span> {new Date().toLocaleDateString()}</p>
                    <p><span className="data-label">% to Month End:</span> {formatCellValue(percentageToMonthEnd, 'percentage')}</p>
                    <div className="leaderboard-list">
                        <div className="legend-title">Leaderboard</div>
                        <p className="leaderboard-item first">1st: {firstPlace}</p>
                        <p className="leaderboard-item second">2nd: {secondPlace}</p>
                        <p className="leaderboard-item third">3rd: {thirdPlace}</p>
                    </div>
                </div>
            </div>

            <div className="bottom-container">
                <div className="small-boxes-container">
                    {/* Two smaller boxes for the other two locations */}
                    {otherLocations.map((location, index) => (
                        <div
                            key={index}
                            className="location-box small"
                            onClick={() => rotateLocations(locationKpis.indexOf(location))}
                        >
                            <h3>{location.Location}</h3>
                            <p>Gross Profit: {formatCellValue(location['gross_profit'], 'currency')}</p>
                            <p>Net Sales: {formatCellValue(location['net_sales'], 'currency')}</p>
                            <p>Cost of Goods: {formatCellValue(location['cost_of_goods'], 'currency')}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default LocationsDashboard;