import React, { useMemo } from 'react';
import { useTable, useSortBy } from 'react-table';
import '../styles/TableViewer.css';

// Column-specific formatting function
const formatCellValue = (value, formatType) => {
    // Handle Firestore Timestamp objects
    if (value && typeof value === 'object' && value.seconds && value.nanoseconds) {
        return new Date(value.seconds * 1000).toLocaleString(); // Convert Firestore Timestamp to readable date
    }

    // Only apply formatting to numerical values, skip strings like Employee Name
    if (typeof value === 'number') {
        if (isNaN(value)) return '0'; // Handle NaN values as 0

        switch (formatType) {
            case 'currency':
                return `$${parseFloat(value).toFixed(2)}`; // Currency formatting
            case 'float':
                return parseFloat(value).toFixed(2); // Truncate to 2 decimal places
            case 'int':
                return parseInt(value, 10); // Convert to integer
            case 'percentage':
                return `${(parseFloat(value) * 100).toFixed(2)}%`; // Convert to percentage
            default:
                return value; // Return the value as is
        }
    }

    // If it's a string or non-numerical value, return it as is
    return value;
};

const TableViewer = ({ dataKey, colFormatDict, timestamp }) => {
    // Filter data based on the provided timestamp (period) to display only matching rows
    const filteredData = useMemo(() => {
        if (!timestamp) return dataKey;
        return dataKey.filter((row) => row['week_summary'] === timestamp); // Filter by timestamp
    }, [dataKey, timestamp]);

    // Dynamically create columns based on the passed data and apply the formatting rules from colFormatDict
    const columns = useMemo(() => {
        if (!filteredData || filteredData.length === 0) return [];

        const columnsArray = Object.keys(filteredData[0] || {}).map((key) => {
            const formatInfo = colFormatDict[key] || {}; // Get format details or default to an empty object

            // If skip is true for this column, skip rendering it
            if (formatInfo.skip) return null;

            const justify = formatInfo.just || 'left'; // Default justification to 'left'
            const header = formatInfo.name || key;

            return {
                Header: header,
                accessor: key,
                Cell: ({ value }) => formatCellValue(value, formatInfo.type || 'default'),
                justify, // Set justification
                pos: formatInfo.pos || null, // Set position for sorting later
                sortType: "alphanumeric",
            };
        }).filter(Boolean);

        // Sort columns based on their 'pos' value if available
        const sortedColumns = columnsArray
            .sort((a, b) => {
                if (a.pos !== null && b.pos !== null) return a.pos - b.pos; // Sort by pos if both have a pos
                if (a.pos !== null) return -1; // Place columns with pos first
                if (b.pos !== null) return 1;
                return 0;
            });

        return sortedColumns;
    }, [filteredData, colFormatDict]);

    const tableInstance = useTable(
        {
            columns,
            data: filteredData || [],
            initialState: {
                sortBy: [
                    {
                        id: 'gp_per_hour', // Replace with the accessor ID of the column you want to sort by
                        desc: true,        // Set to true for descending order or false for ascending
                    },
                ],
            },
        },
        useSortBy // Enable sorting
    );

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

    if (!filteredData || filteredData.length === 0) {
        return <div>No Data Available</div>;
    }

    return (
        <div className="table-container">
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()} key={`header-group-${headerGroup.id}`}>
                            {headerGroup.headers.map((column) => (
                                <th
                                    key={`header-${column.id}`}
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                    style={{
                                        textAlign: column.justify, // Apply custom justification
                                        border: '1px solid black', // Add border to header cells
                                    }}
                                >
                                    {column.render('Header')}
                                    <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? ' 🔽'
                                                : ' 🔼'
                                            : ''}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} key={`row-${row.id}`}>
                                {row.cells.map((cell) => {
                                    const { key, ...cellProps } = cell.getCellProps();
                                    return (
                                        <td
                                            key={`cell-${row.id}-${cell.column.id}`}
                                            {...cellProps}
                                            style={{
                                                textAlign: cell.column.justify, // Apply custom justification to body cells
                                                border: '1px solid black', // Add border to body cells
                                            }}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default TableViewer;
