// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCPBc5FtDlLEKKRPGkVZ5XPOkzCLyFiqrQ",
    authDomain: "qfr-app.firebaseapp.com",
    projectId: "qfr-app",
    storageBucket: "qfr-app.appspot.com",
    messagingSenderId: "552824834504",
    appId: "1:552824834504:web:686b7d114dd2eaeff258fd",
    measurementId: "G-RX2GCKH1FT"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Authentication and Firestore
export const auth = getAuth(app);
export const firestore = getFirestore(app);
