import React, { useContext } from 'react';
import TableViewer from './TableViewer';
import { DataContext } from '../components/DataContext';
import '../styles/Dashboard.css';

const Dashboard = () => {
    // Access the employee and location KPIs from the DataContext
    const { employeeKpis, locationKpis, loadingEmployee, loadingLocation } = useContext(DataContext);

    // Define column formatting for employees and locations
    const employeeColumnFormatDict = {
        "Employee": "default",
        "Total GP": "currency",
        "GP / Hour": "currency",
        "Est. Commission": "currency",
        "Est. Comm / Hour": "currency",
        "Total Sales": "currency",
        "Discounts": "currency",
        "Discount %": "percentage",
        "COGS": "currency",
        "COGS %": "percentage",
        "Repairs": "int",
        "Sales": "int",
        "Phone (R)": "int",
        "Tablet (R)": "int",
        "Other (R)": "int",
        "Solder (R)": "int",
        "CPO (S)": "int",
        "Protection (S)": "int",
        "Accessories (S)": "int",
    };

    const locationColumnFormatDict = {
        "Location": "default",
        "Gross Profit": "currency",
        "Net Sales": "currency",
        "Cost of Goods": "currency",
        "Daily Goal": "currency",
        "% to Goal": "percentage",
        "% to Month End": "percentage",
        "Labor vs Profit": "percentage",
        "Progress": "percentage",
    };

    return (
        <div className="dashboard-container">
            <h1>Raw Data Dashboard</h1>
            <h3>Click one of the side menu buttons</h3>

            {/* Employee KPI Table */}
            <div className="table-section">
                <h2>Employee KPIs</h2>
                {loadingEmployee ? (
                    <p>Loading Employee Data...</p>
                ) : (
                    <TableViewer dataKey={employeeKpis} colFormatDict={employeeColumnFormatDict} />
                )}
            </div>

            {/* Location KPI Table */}
            <div className="table-section">
                <h2>Location KPIs</h2>
                {loadingLocation ? (
                    <p>Loading Location Data...</p>
                ) : (
                    <TableViewer dataKey={locationKpis} colFormatDict={locationColumnFormatDict} />
                )}
            </div>
        </div>
    );
};

export default Dashboard;
