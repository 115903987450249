import React, { useState } from 'react';
import { auth } from '../utils/firebaseConfig';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import '../styles/Login.css';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isRegisterMode, setIsRegisterMode] = useState(false); // Track whether we're in login or register mode
    const navigate = useNavigate();

    const approvedEmails = process.env.REACT_APP_APPROVED_EMAILS.split(',');

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage('');

        if (!approvedEmails.includes(email)) {
            setErrorMessage('This email is not approved to access the system.');
            return;
        }

        try {
            if (isRegisterMode) {
                // Attempt to register the user
                await createUserWithEmailAndPassword(auth, email, password);
                setErrorMessage('Registration successful. Please log in.');
                setIsRegisterMode(false);
            } else {
                // Attempt to login the user
                await signInWithEmailAndPassword(auth, email, password);
                navigate('/dashboard'); // Redirect on successful login
            }
        } catch (error) {
            if (isRegisterMode) {
                // Handle registration errors
                if (error.code === 'auth/email-already-in-use') {
                    setErrorMessage('This email is already registered. Please log in.');
                } else {
                    setErrorMessage('Registration failed. Please try again.');
                }
            } else {
                // Handle login errors
                if (error.code === 'auth/wrong-password') {
                    setErrorMessage('Incorrect password. Please try again.');
                } else if (error.code === 'auth/user-not-found') {
                    setErrorMessage('No account found with this email.');
                } else {
                    setErrorMessage('Login failed. Please try again later.');
                }
            }
        }
    };

    // Toggle between Login and Register modes
    const toggleMode = () => {
        setIsRegisterMode((prevMode) => !prevMode);
        setErrorMessage('');
    };

    return (
        <div className="login-page">
            <div className="login-container">
                <h2>{isRegisterMode ? 'Register' : 'Login'}</h2>
                <form onSubmit={handleFormSubmit}>
                    <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <button type="submit">{isRegisterMode ? 'Register' : 'Login'}</button>
                </form>
                {errorMessage && <p className="error">{errorMessage}</p>}
                <div> </div>
                <button type="button" onClick={toggleMode}>
                    {isRegisterMode ? 'Already have an account?' : "Don't have an account yet?"}
                </button>
            </div>
        </div>
    );
};

export default Login;
